* {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'swipe medium', sans-serif;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

html {
  scroll-behavior: smooth;
}

li,
ul {
  list-style: none;
  padding: 0;
}

button {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background: transparent;
}

button:focus {
  border: 1px solid #b3d4ff;
  outline: none;
  box-shadow: 0 0 4px rgba(24, 144, 255, 0.4);
}

a {
  text-decoration: none;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
th,
td {
  font-weight: normal;
  line-height: 1.5;
  color: #002140;
}

a,
button {
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-internal-autofill-selected {
  -webkit-text-fill-color: #1a2849;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
}

#root {
  z-index: 0;
}

#portal-root {
  z-index: 1;
}

@font-face {
  font-family: 'swipe regular';
  src: url('./assets/fonts/SF-Pro-Display-Regular.otf');
}

@font-face {
  font-family: 'swipe medium';
  src: url('./assets/fonts/SF-Pro-Display-Medium.otf');
}

@font-face {
  font-family: 'swipe semi';
  src: url('./assets/fonts/SF-Pro-Display-Semibold.otf');
}

@font-face {
  font-family: 'swipe bold';
  src: url('./assets/fonts/SF-Pro-Display-Bold.otf');
}
@font-face {
  font-family: 'swipe heavy';
  src: url('./assets/fonts/SF-Pro-Display-Heavy.otf');
}

@font-face {
  font-family: 'klavika medium';
  src: url('./assets/fonts/klavika-medium.otf');
}

@font-face {
  font-family: 'klavika light';
  src: url('./assets/fonts/klavika-light.otf');
}
